import styled, { css } from 'styled-components';
import { Container as ModalBtn } from '@/components/ui/ModalBtn/ModalBtn.styled';
import { color, mediaBreakpointUp, font, hover, anglePositionStyles, offset, adaptiveHardValues } from '@/style/mixins';
import { Colors } from '@/style/colors';
import Image from '@/components/common/Image/Image';
import variables from '@/style/variables';

export const CardIcon = styled(Image)`
    align-self: flex-end;

    ${adaptiveHardValues('width', 160, 92, 64)};
    ${adaptiveHardValues('height', 160, 92, 64)};

    ${mediaBreakpointUp('xl')} {
        span {
            transition: transform 0.3s ease-in-out;
        }
    }
`;

export const Container = styled.div<{ type: string; bgColor?: Colors; textColor?: Colors }>`
    position: relative;
    border-radius: ${variables.corners.smallCard};
    color: ${({ textColor }) => color(textColor ? textColor : 'text-primary')};
    background-color: ${({ bgColor }) => color(bgColor ? bgColor : 'white')};

    ${ModalBtn} {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        position: absolute;
        inset: 0;
        z-index: 2;
    }

    ${({ type }) =>
        type === 'icon' &&
        css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            ${offset('m', 'padding')};
            ${adaptiveHardValues('min-height', 288, 271, 160)}

            ${mediaBreakpointUp('xl')} {
                ${CardAngle} {
                    width: 40px;
                    height: 40px;

                    svg {
                        fill: ${color('light-blue-background')};
                    }
                }
            }

            ${CardTextContainer} {
                flex: 1;
            }

            ${hover(`
                ${CardIcon} {
                    span {
                        transform: scale(1.04);
                }
            `)};
        `}

    ${({ type }) =>
        type === 'text' &&
        css`
            padding: 20px;
            min-height: 100px;

            ${mediaBreakpointUp('md')} {
                padding: 24px;
                min-height: 240px;

                ${mediaBreakpointUp('md')} {
                    min-height: 200px;
                }

                ${mediaBreakpointUp('xl')} {
                    min-height: 240px;
                }

                ${CardAngle} {
                    width: 40px;
                    height: 40px;
                    svg {
                        fill: ${color('light-blue-background')};
                    }
                }
            }
        `}

    ${({ type }) =>
        type === 'image' &&
        css`
            display: flex;
            flex-direction: column-reverse;

            ${mediaBreakpointUp('md')} {
                flex-direction: row;
                justify-content: space-between;
                height: 200px;
            }

            ${mediaBreakpointUp('xl')} {
                height: 240px;
            }

            ${CardAngle} {
                ${mediaBreakpointUp('md')} {
                    display: block;
                    width: 75px;
                    height: 75px;
                }

                ${mediaBreakpointUp('xl')} {
                    width: 90px;
                    height: 90px;
                }

                svg {
                    fill: ${color('white')};
                }
            }

            ${CardTextContainer} {
                padding: 20px;

                ${mediaBreakpointUp('md')} {
                    padding: 24px 40px 24px 24px;
                }
                ${mediaBreakpointUp('xl')} {
                    padding: 24px 40px 24px 24px;
                }
            }
            ${CardImageContainer} {
                max-height: 100px;
                margin: 8px;

                ${mediaBreakpointUp('md')} {
                    max-height: 100%;
                }

                img {
                    width: 100%;
                    border-radius: 10px;
                    max-height: 100px;
                    object-fit: cover;
                    overflow: hidden;

                    ${mediaBreakpointUp('md')} {
                        min-height: 100%;
                        order: -1;
                        height: auto;
                    }
                }
            }
        `}

${({ type }) =>
        type === 'png' &&
        css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 310px;
            ${offset('m', 'padding')};

            ${CardTextContainer} {
                justify-content: start;
            }

            ${CardImageContainer} {
                position: absolute;
                z-index: 0;
                margin: 0;
                width: 120px;
                height: 120px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            ${mediaBreakpointUp('xl')} {
                height: 166px;

                ${CardTextContainer} {
                    position: relative;
                    z-index: 1;
                    gap: 12px;
                    max-width: 378px;
                }

                ${CardImageContainer} {
                    top: 0;
                    left: 100%;
                    transform: translateX(-100%);
                    width: 166px;
                    height: 166px;
                }
                ${CardAngle} {
                    width: 28px;
                    height: 28px;
                    svg {
                        fill: ${color('light-blue-background')};
                    }
                }
            }
        `}

${({ type }) =>
        type === 'link' &&
        css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 310px;
            ${offset('m', 'padding')};

            ${CardTextContainer} {
                justify-content: start;
            }

            ${CardImageContainer} {
                position: absolute;
                z-index: 0;
                margin: 0;
                width: 120px;
                height: 120px;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }

            ${mediaBreakpointUp('xl')} {
                height: 166px;

                ${CardTextContainer} {
                    position: relative;
                    z-index: 1;
                    gap: 12px;
                    max-width: 378px;
                }

                ${CardImageContainer} {
                    top: 0;
                    left: 100%;
                    transform: translateX(-100%);
                    width: 166px;
                    height: 166px;
                }
                ${CardAngle} {
                    width: 28px;
                    height: 28px;
                    svg {
                        fill: ${color('light-blue-background')};
                    }
                }
            }
        `}
`;

export const CardImageContainer = styled.div`
    position: relative;
    flex-shrink: 0;

    ${mediaBreakpointUp('md')} {
        width: 184px;
    }

    ${mediaBreakpointUp('xl')} {
        width: 224px;
    }
    svg {
        fill: ${color('white')};
    }

    img {
        border-radius: ${variables.corners.smallCard};
    }
`;

export const CardLinkWrapper = styled.a`
    position: absolute;
    inset: 0;
    z-index: 2;
`;

export const CardTextContainer = styled.div`
    height: 100%;
    display: flex;
    /* flex: 1; */
    /* justify-content: end; */
    flex-direction: column;
    ${offset('s', 'gap')}
`;

export const CardTitle = styled.h3<{ type: string }>`
    position: relative;
    z-index: 1;
    ${font('h3')};

    span {
        ${({ type }) => type !== 'icon' && font(`h2`)};
        ${({ type }) => type === 'icon' && `color: ${color('blue')};`}
    }
`;

export const CardDescription = styled.p`
    ${font('text2-sb')}
`;

export const CardAngle = styled.div<{ position: string }>`
    display: none;

    ${mediaBreakpointUp('md')} {
        position: absolute;
        overflow: hidden;
        z-index: 0;

        ${({ position }) => anglePositionStyles(position)};
    }

    ${mediaBreakpointUp('xl')} {
        display: block;
    }
`;

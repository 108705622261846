import React from 'react';
import {
    CardAngle,
    CardTextContainer,
    CardImageContainer,
    CardTitle,
    CardIcon,
    CardDescription,
    Container,
    CardLinkWrapper
} from './PrimaryCard.styled';
import Angle from '@/icons/cards/new/angle.svg';
import Image from '@/components/common/Image/Image';
import ModalBtn from '@/components/ui/ModalBtn/ModalBtn';
import Picture, { PictureType } from '@/components/common/Picture/Picture';
import Link from 'next/link';
import { Colors } from '@/style/colors';
import { clearFromNBSP, sendMetrik } from '@/helpers';
import { IMetrika } from '@/interfaces';

export interface IPrimaryCard extends IMetrika {
    href?: string;
    title: string;
    type: 'text' | 'image' | 'icon' | 'png' | 'link';
    description?: string;
    background: PictureType;
    bgColor?: Colors;
    textColor?: Colors;
    icon?: { src: string };
    modal?: { id: string };
    angles?: string[];
}

const PrimaryCard = ({
    href,
    icon,
    title,
    description,
    background,
    angles,
    type,
    modal,
    bgColor,
    textColor,
    metriksSample
}: IPrimaryCard) => {
    const renderImageType = () => (
        <>
            <CardTextContainer>
                <CardTitle dangerouslySetInnerHTML={{ __html: title }} type={type} />
                {description && <CardDescription dangerouslySetInnerHTML={{ __html: description }} />}
            </CardTextContainer>
            <CardImageContainer>
                <Picture {...background} />
                {background?.angles?.map((position, index) => {
                    return (
                        <CardAngle key={index} position={position}>
                            <Angle />
                        </CardAngle>
                    );
                })}
            </CardImageContainer>
        </>
    );

    const renderTextType = () => (
        <CardTextContainer>
            <CardTitle dangerouslySetInnerHTML={{ __html: title }} type={type} />
            {description && <CardDescription dangerouslySetInnerHTML={{ __html: description }} />}
        </CardTextContainer>
    );

    const renderIconType = () => (
        <>
            {modal ? (
                <ModalBtn id={modal.id} />
            ) : (
                <Link href={href ?? '/'} passHref prefetch={false}>
                    <CardLinkWrapper />
                </Link>
            )}
            {description ? (
                <CardTextContainer>
                    <CardTitle dangerouslySetInnerHTML={{ __html: title }} type={type} />
                    <CardDescription dangerouslySetInnerHTML={{ __html: description }} />
                </CardTextContainer>
            ) : (
                <CardTitle dangerouslySetInnerHTML={{ __html: title }} type={type} />
            )}
            <CardIcon {...icon} width={160} height={160} quality={95} />
        </>
    );

    const renderPngType = () => (
        <>
            <CardTextContainer>
                <CardTitle dangerouslySetInnerHTML={{ __html: title }} type={type} />
                {description && <CardDescription dangerouslySetInnerHTML={{ __html: description }} />}
            </CardTextContainer>
            <CardImageContainer>
                {background && (
                    <Image src={background.src} alt={background.alt} objectFit="contain" width={166} height={166} quality={95} />
                )}
            </CardImageContainer>
        </>
    );

    const renderLinkType = () => (
        <>
            <Link href={href ?? '/'} passHref prefetch={false}>
                <CardLinkWrapper />
            </Link>

            <CardTextContainer>
                <CardTitle dangerouslySetInnerHTML={{ __html: title }} type={type} />
                {description && <CardDescription dangerouslySetInnerHTML={{ __html: description }} />}
            </CardTextContainer>

            <CardImageContainer>
                {background && <Image src={background.src} alt={background.alt} objectFit="contain" simple />}
            </CardImageContainer>
        </>
    );
    return (
        <Container
            bgColor={bgColor}
            textColor={textColor}
            type={type}
            onClick={() => {
                metriksSample &&
                    sendMetrik(metriksSample.event, metriksSample.category, {
                        [metriksSample.action]: clearFromNBSP(title)
                    });
            }}
        >
            {type === 'icon' && renderIconType()}
            {type === 'text' && renderTextType()}
            {type === 'image' && renderImageType()}
            {type === 'png' && renderPngType()}
            {type === 'link' && renderLinkType()}

            {angles?.map((position, index) => {
                return (
                    <CardAngle key={index} position={position}>
                        <Angle />
                    </CardAngle>
                );
            })}
        </Container>
    );
};

export default PrimaryCard;
